<template>
    <div>
        <div class="background-image"></div>
        <div class="overlay">
            <el-container>
                <el-header>
                    <el-row>
                        <el-col span="1">
                            <span>&nbsp;</span>
                        </el-col>
                        <el-col :span="7">

                                <a href="/#/Homes/Hall">
                                    <el-avatar :size="70" :src="circleUrl1">
                                    </el-avatar>
                                </a>

                           
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple-light">
                                <h1>Welcome to HelloWorld_Debater </h1>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <h1>欢迎来到计算机学院辩论队</h1>
                            </div>
                        </el-col>
                    </el-row>

                </el-header>
                <el-main>
                    <el-col :span="2">
                        <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
                            @close="handleClose">
                            <el-menu-item index="1">
                                <i class="el-icon-menu"></i>
                                <span slot="title">团队介绍</span>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <i class="el-icon-menu"></i>
                                <span slot="title">比赛活动</span>
                            </el-menu-item>
                            <el-menu-item index="3">
                                <i class="el-icon-document"></i>
                                <span slot="title">历史传承</span>
                            </el-menu-item>
                            <el-menu-item index="4">
                                <i class="el-icon-setting"></i>
                                <span slot="title">未来展望</span>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                    <el-col span="20">
                        <el-col :xs="5" :sm="5" :md="4" v-for="o in cardLists" :key="o.number">
                            <el-card class="MyCard">
                                <div class="upper">
                                    <el-image style="width: 130px; height: 130px" :src="o.img" :fit="fit"></el-image>
                                </div>
                                <div class="lower">
                                    <span class="line1">{{ o.title }}</span>
                                    <span class="line2"></span>

                                </div>
                            </el-card>
                        </el-col>
                    </el-col>
                </el-main>
            </el-container>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            circleUrl1: "https://www.wust.edu.cn/images/logo.png",
            cardLists: [
                { number: 1, title: '队长 朱静宜', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E6%9C%B1%E9%9D%99%E6%80%A1.jpg' },
                { number: 2, title: '孙昊宇', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E5%AD%99%E6%B5%A9%E5%AE%87.jpg' },  // 注意：这里的路径仍然可以使用 require 或保持为字符串，取决于你的构建配置
                { number: 3, title: '赵梦宇', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E8%B5%B5%E6%A2%A6%E5%AE%87.jpg' },
                { number: 4, title: '张喜吉', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/zxj.jpg' },
                { number: 5, title: '林远强', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E6%9E%97%E8%BF%9C%E5%BC%BA.jpg' },
                { number: 6, title: '郭振乾', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E9%83%AD%E6%8C%AF%E4%B9%BE.jpg' },
                { number: 7, title: '张月超', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/ZR5%7ET9%5BK9%28YFDTUI_%7DM7ADR.png' },
                { number: 8, title: '孙宇严', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E5%AD%99%E5%AE%87%E4%B8%A5.jpg' },
                { number: 9, title: '邓天垚', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E9%82%93%E5%A4%A9hao.jpg' },
                { number: 10, title: '刘阳', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/ly.jpg' },
                { number: 11, title: '谭嘉怡', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E8%B0%AD%E5%98%89%E4%BB%AA.jpg' },
                { number: 12, title: '王博恩', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/wbe.jpg' },
                { number: 13, title: '费益明', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E5%B0%8F%E6%98%8E.jpg' },
                { number: 14, title: '陈亮', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E9%99%88%E4%BA%AE.jpg' },
                { number: 15, title: '戴思诗', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E6%88%B4%E6%80%9D%E8%AF%97.jpg' },
                { number: 16, title: '陈欣雨', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E9%99%88%E6%AC%A3%E5%AE%87.jpg' },
                { number: 17, title: '李宇涵', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/%E6%9D%8E%E9%9B%A8%E6%B6%B5.jpg' },
                { number: 18, title: 'nameless', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/nameless.jpg' },
                { number: 19, title: '述回—帝名', img: 'https://jspv.oss-cn-beijing.aliyuncs.com/shdm.jpg' },
            ],
        }
    }
}
</script>

<style>
.el-header,
.el-footer {
    /* background-color: #0a0a0a; */

    color: #fffefe;

    height: 95px;
}

.el-card {
    width: 200px;
    /* 调整卡片宽度 */
    height: 180px;
    /* 调整卡片高度 */
    margin: 15px;
}

.overlay {
    position: absolute;
    /* 或 fixed，取决于你是否希望它相对于视口定位 */
    z-index: 2;
    width: 100%;
    /* 根据需要调整 */
    height: 100%;
    /* 根据需要调整 */
    /* 移除 display: flex; 如果你不需要弹性布局 */
    top: 0;
    /* 或你想要的任何偏移值 */
    left: 0;
    /* 或你想要的任何偏移值 */
    /* 如果不希望覆盖整个父元素，可以调整 width 和 height */
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://jspv.oss-cn-beijing.aliyuncs.com/1.jpg');
    background-size: cover;
    background-position: center;
    z-index: 1;
}
</style>