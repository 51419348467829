import Vue from 'vue';
import Router from 'vue-router';
import MainPage from '@/components/MainPage.vue';
Vue.use(Router);
//创建一个路由器
const routes = [
	{
	  path: '/',
	  name: 'MainPage',
	  component: MainPage
	},
];
const router = new Router({
	routes
  })
export default router